<template>
  <div>
    <v-app-bar dark color="#19647E">
      <v-icon large class="mr-3">people</v-icon>
      <v-toolbar-title>Users</v-toolbar-title>
    </v-app-bar>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-flex xs12 md6>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <a
          class="mr-5"
          :href="`/vendor/upUser/status/${item.userId}/${$route.query.projectCode}`"
          target="_blank"
          >View</a
        >
      </template>
    </v-data-table>
  </div>
</template>


<script>
import DataServiceVD from "./../services/dataServiceVD.js";
const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      search: "",
      users: [],
      headers: [
        { text: "UserId", value: "userId" },
        { text: "Name", value: "name" },
        { text: "Mobile Number", value: "mobileNumber" },
        { text: "Email", value: "email" },
        { text: "Date of installation", value: "joinedAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  mounted() {
    this.getAllUsers();
  },

  methods: {
    async getAllUsers() {
      this.$setLoader();
      dataServiceVD.getAllusers(this.$route.query).then((res) => {
        this.users = res.data;
        this.$disableLoader();
      });
    },
  },
};
</script>